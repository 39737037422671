import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const NextBtn = styled(Button)({
  height: '40px',
  lineHeight: '40px',
  maxHeight: '40px',
  width: '134px',
  color: 'rgba(40, 40, 40, 1)',
  fontSize: 14,
  fontFamily: 'JetBrainsMono',
  textTransform: 'initial',
  background: 'linear-gradient(180deg, #1EA7E4 0%, #01BBBE 100%)',
  border: 'none',
  borderRadius: '8px',
  '&:hover': {
    boxShadow: ' 0px 0px 32px 0px rgba(0, 147, 209, 1)',
  },
  '&:disabled': {
    background: 'rgba(0, 51, 73, 1)',
    color: 'rgba(255, 255, 255, 0.38)',
    cursor: 'not-allowed',
  },
})
