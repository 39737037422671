import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import Bg from 'assets/imgs/airdrop/bg.svg'
import LampVideo from 'assets/imgs/airdrop/lamp.webm'
import LampVideoM from 'assets/imgs/airdrop/lamp-m.webm'
import { ReactComponent as NotifyIcon } from 'assets/imgs/airdrop/notify.svg'
import { ReactComponent as SpinIcon_dark } from 'assets/imgs/airdrop/spin_dark.svg'
import { ReactComponent as SpinIcon_light } from 'assets/imgs/airdrop/spin_light.svg'
import { ReactComponent as SpinsIcon } from 'assets/imgs/airdrop/spins.svg'
import { ReactComponent as Twitter2Icon } from 'assets/imgs/airdrop/twitter2.svg'
import axios from 'axios'
import { CopyCodeButton, CopyLinkButton, Pending, PostButton } from 'components/Button'
import Column from 'components/Column'
import { StyledOverlay } from 'components/Modal'
import Row, { RowBetween } from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { ToastError, ToastSuccess } from 'components/Toast'
import { useActiveChainId } from 'connection/useActiveChainId'
import { AIRDROP_API } from 'constants/misc'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { useAtomValue } from 'jotai/utils'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import { NoTokensStateM } from 'pages/Liquidity/Table'
import qs from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { BN, fromWei } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { useSignMessage } from 'wagmi'

import AccountInfo from './AccountInfo'
import AirdropActivities from './AirdropActivities'
import { queryURLParams } from './AirdropActivities/urlParams'
import BasicInfo from './BasicInfo'
import Campaign from './Campaign'
import HistoryListModal from './HistoryList'
import { inviteCodeAtom, luckDrawAtom, usePtsInfoData } from './hooks'
import { teamGoals } from './mis_team'
import { CumulativeProgressBar } from './ProgressBar'
import useRankingData, { epochAtom } from './Ranking/rankingHooks'
import TotalInfo from './TotalInfo'
import { useQueryUserVolumes } from './useQueryAirdrop'

const StyledInfoBox = styled(Box)`
  margin-top: 98px;
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 78px;
  }
`

const StyledLuckyDraw = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 30px 24px 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.luckyBorder};
  position: relative;

  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 30px 16px 24px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    padding: 30px 12px 24px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 30px 8px 24px;
  }
`

const StyledDrawBox = styled(Box)<{ isActive: boolean }>`
  position: absolute;
  top: -46px;
  left: 50%;
  transform: translateX(-50%);
  width: 570px;
  max-width: 100%;
  height: 327px;
  background-image: url(${({ theme, isActive }) => (isActive ? theme.LuckyDrawBgActive : theme.LuckyDrawBg)});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  .luckNumber {
    margin-top: 124px;
    position: relative;
    .lamp {
      position: absolute;
      z-index: 9;
    }
  }
  .spinsBox {
    margin-top: 24px;
  }
  .spins {
    margin-left: 12px;
  }
  .spin {
    margin-right: 30px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    top: -22px;
    width: 340px;
    height: 196px;
    .spinsBox {
      margin-top: -12px;
    }
    .luckNumber {
      margin-top: 68px;
    }
    .spin {
      margin-right: 12px;
    }
  }
`

const StyledSpinsBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 32px 8px 30px;
  border-radius: 24px;
  background: ${({ theme }) => theme.referralSpins};
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 4px 10px 4px 10px;
  }
`

const StyledDraw = styled(Box)`
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  text-indent: -6px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 60px;
    height: 60px;
  }
`

const StyledReferralTitleBox = styled(Box)`
  position: absolute;
  right: 30px;
  top: -30px;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.referralTitleBorder};
  background: ${({ theme }) => theme.referralTitleBg};
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 6px 0 6px 12px;
  }
`

const StyledCumulativeInfo = styled.div`
  margin-top: 296px;
  padding: 24px;
  border-radius: 8px;
  background: ${({ theme }) => theme.cumulativeInfoBg};
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 12px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 8px;
    margin-top: 186px;
  }
`

const StyledTradingVolume = styled.div`
  /* padding-bottom: 24px;
  border-bottom: 1px dashed ${({ theme }) => theme.sectionBorder}; */
`

const StyledCollect = styled.div`
  margin-top: 24px;
  padding-bottom: 18px;
  border-bottom: 1px dashed ${({ theme }) => theme.sectionBorder};
`

const StyledTradeWeekly = styled.div`
  margin-top: 24px;
`

const StyledReferralHistory = styled(StyledCumulativeInfo)`
  margin-top: 8px;
  padding: 10px 16px 0;
  .title {
    padding: 12px 0;
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorder};
  }
  .referralItem {
    padding: 16px 0;
    &:not(:last-child) {
      border-bottom: 1px dashed ${({ theme }) => theme.sectionBorder};
    }
  }
  .address {
    text-decoration: underline;
  }
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 10px 12px 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 10px 8px 0;
  }
`

const StyledReferralCode = styled.div`
  width: 100%;
  padding: 10px 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.referralBg};
`

// eslint-disable-next-line import/no-unused-modules
export default function Airdrop() {
  const theme = useTheme()

  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isSmallScreen2 = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)

  const url = window.location.href
  const urlParams: any = queryURLParams(url)
  const { account } = useActiveChainId()
  const publicAddress = account?.toLowerCase()

  const { userPtsInfo } = useRankingData()

  const [referralsList, setReferralsList] = useState<any>([])
  const [isBind, setIsBind] = useState<any>(false)
  const [isPending, setIsPending] = useState<any>(false)
  const [isDisabled, setIsDisabled] = useState<any>(true)
  const [userInfo, setUserInfo] = useState<any>({})
  const [basicPoints, setBasicPoints] = useState<any>(0)
  const [timeSeconds, setTimeSeconds] = useState<any>('')
  const [showLamp, setShowLamp] = useState<boolean>(false)
  const [luckNumber, setLuckNumber] = useState<any>('')
  const [invitationCode, setInvitationCode] = useState<any>(Cookies.get('invitation_code'))
  const [twitter_id, setTwitter_id] = useState<any>(Cookies.get('twitter_id' + publicAddress))
  const [twitter_token, setTwitter_token] = useState<any>(Cookies.get('twitter_token' + publicAddress))
  const [discord_id, setDiscord_id] = useState<any>(Cookies.get('discord_id' + publicAddress))
  const [discord_token, setDiscord_token] = useState<any>(Cookies.get('discord_token' + publicAddress))
  const [twitterUser, setTwitterUser] = useState<any>(Cookies.get('twitterUser' + publicAddress))
  const [discordUser, setDiscordUser] = useState<any>(Cookies.get('discordUser' + publicAddress))
  const [isValidated, setIsValidated] = useState<any>(Cookies.get('isValidated' + publicAddress))
  const [refCode, setRefCode] = useState<any>('')
  const [drawMsg, setDrawMsg] = useState<any>('')
  const [drawSuccess, setDrawSuccess] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const epoch = useAtomValue(epochAtom)
  const luckDraw = useAtomValue(luckDrawAtom)
  const inviteCode = useAtomValue(inviteCodeAtom)

  const { roundPtsList } = usePtsInfoData()
  const userRoundPtsInfo = useMemo(() => {
    if (!roundPtsList || epoch < 1) return
    return roundPtsList[epoch - 1]
  }, [epoch, roundPtsList])

  const handlerEnter = useCallback(async () => {
    if (publicAddress) {
      setIsPending(true)
      const parmasData = {
        ref_code: refCode ? refCode : Cookies.get('refCode' + publicAddress),
      }

      await axios
        .post(`${AIRDROP_API}/checkinvite`, parmasData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setIsValidated(response.data ? 'true' : 'false')
          Cookies.set('isValidated' + publicAddress, response.data ? 'true' : 'false', {
            expires: 30,
            SameSite: 'Strict',
          })
          response.data ? ToastSuccess('Validated success!') : ToastError('Validated failed!')
        })
        .catch((error) => {
          console.error('checkinvite error', error)
        })
        .finally(() => {
          setIsPending(false)
        })
    }
  }, [publicAddress, refCode])

  const [searchParams, setSearchParams] = useSearchParams()
  const ref = searchParams.get('ref')
  useEffect(() => {
    if (ref) {
      // console.log('ref', ref)
      setRefCode(ref)
      Cookies.set('refCode' + publicAddress, ref, {
        expires: 30,
        SameSite: 'Strict',
      })
      setIsDisabled(false)
      // handlerCheckCode(ref)
    }
  }, [publicAddress, ref])

  const [showList, setShowList] = useState(false)
  const handleDismissList = () => {
    setShowList(false)
  }

  const invite = useMemo(() => {
    return invitationCode ? invitationCode : inviteCode
  }, [invitationCode, inviteCode])

  const [, setCopied] = useCopyClipboard()
  const copyCode = useCallback(() => {
    setCopied(invite ?? '')
    ToastSuccess('Copied to clipboard')
  }, [invite, setCopied])

  const copyLink = useCallback(() => {
    setCopied(`${url}?ref=${invite}` ?? '')
    ToastSuccess('Copied to clipboard')
  }, [invite, setCopied, url])

  const handlerPost = () => {
    const refUrl = `https://app.roguex.io/%23/points?ref=${invite}`

    window.open(
      `https://twitter.com/intent/post?text=@Roguex_io is the "Uniswap" for perpetual trading on Blast!%0A%0AI'm taking part in the RogueX Airdrop event!%0A%0AUse my invitation code to join the fun!%0A%0A${refUrl}%0A%0A&hashtags=BlastAirdrop,BlastL2`
    )
  }

  const { data } = useQueryUserVolumes()
  const tradingVolume_total = data?.userVolumes?.reduce((pre: any, curr: any) => {
    pre = pre.plus(fromWei(curr.totalValume))
    return pre
  }, BN(0))

  const progressBarVal = useMemo(() => {
    // const val = tradingVolume_total / 10000
    const val = userRoundPtsInfo?.current_week_vol / 10000
    return val > 1 ? 100 : val * 100
  }, [userRoundPtsInfo?.current_week_vol])

  const weeklyId = Math.round(Date.now() / 1000 / (7 * 86400))
  const tradingVolume_weekly_arr = data?.userVolumes?.filter((item: any) => item.weeklyId == weeklyId)
  const tradingVolume_weekly = tradingVolume_weekly_arr?.reduce((pre: any, curr: any) => {
    pre = pre.plus(fromWei(curr.totalValume))
    return pre
  }, BN(0))

  const location = useLocation()
  const { twitter_token: newToken_twitter }: any = qs.parse(location.search.slice(1))
  const { discord_token: newToken_discord }: any = qs.parse(location.search.slice(1))
  // console.log('newToken_twitter', newToken_twitter, 'newToken_discord', newToken_discord)
  if (newToken_twitter && publicAddress) {
    Cookies.set('newToken_twitter' + publicAddress, newToken_twitter, {
      expires: 30,
      SameSite: 'Strict',
    })
  }
  if (newToken_discord && publicAddress) {
    Cookies.set('newToken_discord' + publicAddress, newToken_discord, { expires: 30, SameSite: 'Strict' })
  }

  useEffect(() => {
    if (twitter_id && twitter_token) {
      axios
        .get(`${AIRDROP_API}/twitter_user/${twitter_id}`, {
          headers: {
            Authorization: `Bearer ${twitter_token}`,
          },
        })
        .then((res) => {
          setTwitterUser(res.data[0])
        })
        .catch((error) => {
          console.error('Error fetching twitterUser data:', error)
          if (error.response && error.response.status === 401) {
            // handle unauthorized error
          }
        })
    } else {
      // const urlParams = new URLSearchParams(window.location.search)
      // const newToken_twitter = urlParams.get('twitter_token')
      // console.log(newToken_twitter, 'newToken')

      if (newToken_twitter && publicAddress) {
        const decodedToken: any = jwtDecode(newToken_twitter.toString())
        const newId = decodedToken?.id
        Cookies.set('twitter_id' + publicAddress, newId, { expires: 30, SameSite: 'Strict' })
        Cookies.set('twitter_token' + publicAddress, newToken_twitter.toString(), { expires: 30, SameSite: 'Strict' })
        setTwitter_token(newToken_twitter.toString())
        axios
          .get(`${AIRDROP_API}/twitter_user/${newId}`, {
            headers: {
              Authorization: `Bearer ${newToken_twitter}`,
            },
          })
          .then((res) => {
            setTwitterUser(res.data[0])
            Cookies.set('twitterUser' + publicAddress, newToken_twitter.toString(), { expires: 30, SameSite: 'Strict' })
          })
          .catch((error) => {
            console.error('Error fetching twitterUser data:', error)
            if (error.response && error.response.status === 401) {
              // handle unauthorized error
            }
          })
      }
    }

    if (discord_id && discord_token) {
      axios
        .get(`${AIRDROP_API}/discord_user/${discord_id}`, {
          headers: {
            Authorization: `Bearer ${discord_token}`,
          },
        })
        .then((res) => {
          setDiscordUser(res.data)
        })
        .catch((error) => {
          console.error('Error fetching discordUser data:', error)
          if (error.response && error.response.status === 401) {
            // handle unauthorized error
          }
        })
    } else {
      // const urlParams = new URLSearchParams(window.location.search)
      // const newToken_discord = urlParams.get('discord_token')

      if (newToken_discord && publicAddress) {
        const decodedToken: any = jwtDecode(newToken_discord.toString())
        const newId = decodedToken?.id
        Cookies.set('discord_id' + publicAddress, newId, { expires: 30, SameSite: 'Strict' })
        Cookies.set('discord_token' + publicAddress, newToken_discord.toString(), { expires: 30, SameSite: 'Strict' })
        setDiscord_token(newToken_discord.toString())
        axios
          .get(`${AIRDROP_API}/discord_user/${newId}`, {
            headers: {
              Authorization: `Bearer ${newToken_discord}`,
            },
          })
          .then((res) => {
            setDiscordUser(res.data)
            Cookies.set('discordUser' + publicAddress, newToken_discord.toString(), { expires: 30, SameSite: 'Strict' })
          })
          .catch((error) => {
            console.error('Error fetching discordUser data:', error)
            if (error.response && error.response.status === 401) {
              // handle unauthorized error
            }
          })
      }
    }
  }, [discord_id, discord_token, newToken_discord, newToken_twitter, publicAddress, twitter_id, twitter_token])

  useEffect(() => {
    if (!publicAddress) return
    axios
      .get(`${AIRDROP_API}/basepoint/${publicAddress}`)
      .then((res) => {
        setBasicPoints(res.data)
      })
      .catch((error) => {
        console.error('basicPoints error', error)
      })

    axios
      .get(`${AIRDROP_API}/invite/${publicAddress}`)
      .then((res) => {
        setReferralsList(res.data)
      })
      .catch((error) => {
        console.error('referrals error', error)
      })
  }, [publicAddress, showLamp])

  useEffect(() => {
    const parmasData = {
      wallet_address: publicAddress,
    }
    axios
      .post(`${AIRDROP_API}/finduser`, parmasData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setIsBind(res.data ? true : false)
        setUserInfo(res.data)
        setInvitationCode(res.data.invatition_code)
        setTwitter_id(res.data ? res.data.twitter_id : Cookies.get('twitter_id' + publicAddress))
        setDiscord_id(res.data ? res.data.discord_id : Cookies.get('discord_id' + publicAddress))
      })
      .catch((error) => {
        console.error('finduser error', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [publicAddress])

  const handlerEnd = () => {
    setShowLamp(false)
    drawSuccess ? ToastSuccess(drawMsg) : ToastError(drawMsg ? drawMsg : 'Draw error')
    setDrawMsg('')
  }

  const { signMessage: signMessageGenerate } = useSignMessage({
    onSuccess: async (data, variables) => {
      const parmasData = {
        ref_code: refCode ? refCode : Cookies.get('refCode' + publicAddress),
        publicAddress,
        signature: data,
        timeSeconds,
        twitter_token,
        discord_token,
      }
      // console.log('parmasData', parmasData)
      await axios
        .post(`${AIRDROP_API}/auth/wallet`, parmasData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          Cookies.set('invitation_code', res.data.invatitionCode, { expires: 30, SameSite: 'Strict' })
          setInvitationCode(res.data.invatitionCode)
          ToastSuccess('Generate success!')
          setIsBind(true)
        })
        .catch((error) => {
          console.error('Generate error', error)
          ToastError(error.response.data.error)
        })
    },
    onError: (error) => {
      console.log('Generate error', error)
      // handlerError(error)
    },
  })

  const { signMessage: signMessageDraw } = useSignMessage({
    onSuccess: async (data, variables) => {
      setShowLamp(true)

      const parmasData = {
        publicAddress,
        signature: data,
        timeSeconds,
      }
      await axios
        .post(`${AIRDROP_API}/luck_draw`, parmasData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          const score = res.data.score
          setLuckNumber([...score])
          setDrawMsg('Draw success!')
          setDrawSuccess(true)
        })
        .catch((error) => {
          console.error('Draw error', error)
          setDrawMsg(error.response.data.error)
          setDrawSuccess(false)
        })
        .finally(() => {
          // setShowLamp(false)
        })
    },
    onError: (error) => {
      console.log('Draw error', error)
      // handlerError(error)
    },
  })

  const handlerGenerate = () => {
    const registerStr = 'Sign in to the RogueX airdrop dapp, Timestamp:'
    const timeSecondsSign = Math.round(Date.now() / 1000)
    setTimeSeconds(timeSecondsSign)
    signMessageGenerate({ message: registerStr + timeSecondsSign.toString() })
  }

  const handlerDraw = useCallback(() => {
    if (luckDraw < 1) {
      ToastError('Oh, you need more trades to get spins')
      return
    }
    const luckDrawStr = 'Spin it now, Timestamp:'
    const timeSecondsSign = Math.round(Date.now() / 1000)
    setTimeSeconds(timeSecondsSign)
    signMessageDraw({ message: luckDrawStr + timeSecondsSign.toString() })
  }, [luckDraw, signMessageDraw])

  return (
    <PageWrapper data-testid="airdrop-page">
      {isLoading ? (
        <StyledOverlay>
          <Pending />
        </StyledOverlay>
      ) : isBind && twitter_id && discord_id && invite ? (
        <>
          <AccountInfo twitterUser={twitterUser} />
          <TotalInfo />
          {epoch > 0 && <BasicInfo />}
          <StyledInfoBox>
            <StyledLuckyDraw>
              <StyledDrawBox isActive={showLamp}>
                <Row justify="center" gap={isSmallScreen2 ? '8px' : '16px'} className="luckNumber">
                  {showLamp && (
                    <video
                      // eslint-disable-next-line react/no-unknown-property
                      x5-video-player-type="h5"
                      controlsList="nodownload noremoteplayback"
                      playsInline
                      muted
                      id="lamp"
                      preload="metadata"
                      autoPlay
                      loop={false}
                      className="lamp"
                      style={{ height: isSmallScreen2 ? '60px' : '90px' }}
                      onEnded={handlerEnd}
                    >
                      <source src={isSmallScreen2 ? LampVideoM : LampVideo} type="video/webm" />
                    </video>
                  )}
                  <StyledDraw>
                    {!showLamp && (
                      <ThemedText.DeprecatedItalic fontSize={isSmallScreen2 ? 48 : 70} fontWeight={700} color="white">
                        {luckNumber ? luckNumber[0] : 0}
                      </ThemedText.DeprecatedItalic>
                    )}
                  </StyledDraw>
                  <StyledDraw>
                    {!showLamp && (
                      <ThemedText.DeprecatedItalic fontSize={isSmallScreen2 ? 48 : 70} fontWeight={700} color="white">
                        {luckNumber ? luckNumber[1] : 0}
                      </ThemedText.DeprecatedItalic>
                    )}
                  </StyledDraw>
                  <StyledDraw>
                    {!showLamp && (
                      <ThemedText.DeprecatedItalic fontSize={isSmallScreen2 ? 48 : 70} fontWeight={700} color="white">
                        {luckNumber ? luckNumber[2] : 0}
                      </ThemedText.DeprecatedItalic>
                    )}
                  </StyledDraw>
                  <StyledDraw>
                    {!showLamp && (
                      <ThemedText.DeprecatedItalic fontSize={isSmallScreen2 ? 48 : 70} fontWeight={700} color="white">
                        {luckNumber ? luckNumber[3] : 0}
                      </ThemedText.DeprecatedItalic>
                    )}
                  </StyledDraw>
                </Row>
                <RowBetween className="spinsBox">
                  <StyledSpinsBox className="spins">
                    <ThemedText.TextSecondary
                      fontSize={isSmallScreen2 ? 12 : 14}
                      fontWeight={700}
                      mr={isSmallScreen2 ? '4px' : '10px'}
                    >
                      <Trans>Spins</Trans>
                    </ThemedText.TextSecondary>
                    <SpinsIcon />
                    <ThemedText.TextPrimary
                      fontSize={isSmallScreen2 ? 18 : 24}
                      fontWeight={700}
                      ml={isSmallScreen2 ? '8px' : '12px'}
                    >
                      {formatAmount(luckDraw, 0, true)}
                    </ThemedText.TextPrimary>
                  </StyledSpinsBox>
                  <div className="spin" style={{ cursor: 'pointer' }} onClick={handlerDraw}>
                    {theme.darkMode ? (
                      <SpinIcon_dark width={isSmallScreen2 ? 60 : 'atuo'} />
                    ) : (
                      <SpinIcon_light width={isSmallScreen2 ? 60 : 'atuo'} />
                    )}
                  </div>
                </RowBetween>
              </StyledDrawBox>
              <StyledCumulativeInfo>
                <StyledTradingVolume>
                  <RowBetween>
                    <Column align="flex-start" gap="8px">
                      <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                        <Trans>This week trading volume</Trans>
                      </ThemedText.TextPrimary>
                      <Box mt="4px">
                        <CumulativeProgressBar value={progressBarVal} />
                      </Box>
                      <ThemedText.TextPrimary fontSize={12}>
                        ${formatAmount(userRoundPtsInfo?.current_week_vol, 2, true)} / $10,000
                      </ThemedText.TextPrimary>
                    </Column>
                    <Column align="flex-end" gap="4px">
                      <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
                        {/* <Trans>+{Math.floor(tradingVolume_total / 10000)}</Trans> */}
                        <Trans>+{Math.floor(userRoundPtsInfo?.current_week_vol / 10000)}</Trans>
                      </ThemedText.TextPrimary>
                      <ThemedText.TextPrimary fontSize={12}>
                        <Trans>Next week</Trans>
                      </ThemedText.TextPrimary>
                    </Column>
                  </RowBetween>
                </StyledTradingVolume>
                {/* <StyledCollect>
                  <RowBetween>
                    <Column align="flex-start" gap="24px">
                      <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                        <Trans>Collect NFT</Trans>
                      </ThemedText.TextPrimary>
                      <Box display="flex" gap="16px">
                        <NFTIcon />
                        <NFTIcon />
                        <NFTIcon />
                      </Box>
                    </Column>
                    <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
                      <Trans>2</Trans>
                    </ThemedText.TextPrimary>
                  </RowBetween>
                </StyledCollect>
                <StyledTradeWeekly>
                  <RowBetween>
                    <Column align="flex-start" gap="8px">
                      <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                        <Trans>Trade $3,000 Weekly</Trans>
                      </ThemedText.TextPrimary>
                      <Box mt="4px">
                        <TradeProgressBar value={50} />
                      </Box>
                      <ThemedText.TextPrimary fontSize={12}>
                        {formatAmount(tradingVolume_weekly, 2, true)}/ 1,000
                      </ThemedText.TextPrimary>
                    </Column>
                    <Column align="flex-end" gap="4px">
                      <NFTIcon />
                    </Column>
                  </RowBetween>
                </StyledTradeWeekly> */}
              </StyledCumulativeInfo>
            </StyledLuckyDraw>
            <StyledLuckyDraw>
              <StyledReferralTitleBox>
                <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%" gap="6px">
                  <div style={{ marginTop: '-30px ' }}>
                    <NotifyIcon />
                  </div>
                  <ThemedText.TextPrimary fontSize={isSmallScreen2 ? 24 : 36} fontWeight={800} mr="8px">
                    <Trans>Referral</Trans>
                  </ThemedText.TextPrimary>
                </Box>
              </StyledReferralTitleBox>
              <Column align="flex-start" gap="16px" mt="28px">
                <StyledReferralCode>
                  <Box
                    display="flex"
                    flexDirection={isSmallScreen2 ? 'column' : 'row'}
                    alignItems="center"
                    justifyContent="space-between"
                    gap="16px"
                  >
                    <ThemedText.TextPrimary fontSize={isSmallScreen2 ? 24 : 18} fontWeight={700} color="referralCode">
                      {invite}
                    </ThemedText.TextPrimary>
                    <Box display="flex " alignItems="center" width={isSmallScreen2 ? '100%' : 'auto'} gap="16px">
                      <PostButton onClick={handlerPost} style={{ flex: isSmallScreen2 ? 1 : 'auto' }}>
                        <Twitter2Icon />
                        <Box ml="8px">
                          <Trans>Post on X</Trans>
                        </Box>
                      </PostButton>
                      <CopyCodeButton onClick={copyCode} style={{ flex: isSmallScreen2 ? 1 : 'auto' }}>
                        <Trans>COPY </Trans>
                      </CopyCodeButton>
                      <CopyLinkButton onClick={copyLink} style={{ flex: isSmallScreen2 ? 1 : 'auto' }}>
                        <Trans> LINK</Trans>
                      </CopyLinkButton>
                    </Box>
                  </Box>
                </StyledReferralCode>
                <Box display="flex" alignItems="center" gap="4px">
                  <ThemedText.TextSecondary fontSize={12}>
                    <Trans>Max Referrals:</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary fontSize={12}>
                    {referralsList?.length}/{teamGoals[userPtsInfo?.team_level]?.maxReferral}
                  </ThemedText.TextPrimary>
                </Box>
              </Column>
              <StyledReferralHistory>
                <RowBetween className="title">
                  <ThemedText.TextSecondary fontSize={14} fontWeight={700}>
                    <Trans>Referral History</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextSecondary
                    fontSize={14}
                    fontWeight={700}
                    color="removeBackground"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowList(true)}
                  >
                    <Trans>{'More>>'}</Trans>
                  </ThemedText.TextSecondary>
                </RowBetween>
                {referralsList?.length == 0 ? (
                  <NoTokensStateM message={<Trans>No Referral History</Trans>} />
                ) : (
                  referralsList?.map((referral: any, i: number) => (
                    <RowBetween className="referralItem" key={i}>
                      <ThemedText.TextSecondary fontSize={14} className="address">
                        {shortenAddress(referral?.wallet_address)}
                      </ThemedText.TextSecondary>
                      <Box display="flex" alignItems="center" gap="8px">
                        <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                          {formatAmount(referral?.pts_total, 2, true)}
                        </ThemedText.TextPrimary>
                        <ThemedText.TextSecondary fontSize={14} fontWeight={700}>
                          <Trans>PTS.</Trans>
                        </ThemedText.TextSecondary>
                      </Box>
                    </RowBetween>
                  ))
                )}
              </StyledReferralHistory>
            </StyledLuckyDraw>
          </StyledInfoBox>
        </>
      ) : isValidated == 'true' ? (
        <AirdropActivities
          basicPoints={basicPoints}
          twitterUser={twitterUser}
          discordUser={discordUser}
          handlerGenerate={handlerGenerate}
        />
      ) : (
        <Campaign
          refCode={refCode}
          setRefCode={setRefCode}
          handlerEnter={handlerEnter}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          isPending={isPending}
        />
      )}
      <HistoryListModal referralsList={referralsList} isOpen={showList} onDismiss={handleDismissList} />
    </PageWrapper>
  )
}
