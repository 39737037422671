import './verificationInput.css'

import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import CodeBg from 'assets/imgs/airdrop/codeBg.svg'
import Column from 'components/Column'
import Row from 'components/Row'
import { useActiveChainId } from 'connection/useActiveChainId'
import Cookies from 'js-cookie'
import VerificationInput from 'react-verification-input'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'

import { NextBtn } from './NextBtn'

export const CampaignPage = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(${({ theme }) => theme.AirdropImgBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledCodeBg = styled(Box)`
  width: 124px;
  height: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${CodeBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
`

const StyledLine = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.primaryBtn_hover};
`

const StyledVerificationInput = styled(VerificationInput)``

export default function Campaign({ refCode, setRefCode, handlerEnter, isPending, isDisabled, setIsDisabled }: any) {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)

  const { account } = useActiveChainId()
  const publicAddress = account?.toLowerCase()

  const handlerComplete = async (v: any) => {
    // console.log('handlerComplete', v)
    setRefCode(v)
    Cookies.set('refCode' + publicAddress, v, {
      expires: 30,
      SameSite: 'Strict',
    })
    setIsDisabled(false)
    // handlerCheckCode(v)
  }
  const handlerChange = (v: any) => {
    // console.log('handlerChange', v)
    setRefCode(v)
    setIsDisabled(true)
  }

  return (
    <CampaignPage data-testid="airdrop-campaign-page">
      <Column gap="8px" style={{ textTransform: 'uppercase', textAlign: 'center' }}>
        <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={400} className="active-gradient-text">
          <Trans>Early Experience</Trans>
        </ThemedText.TextPrimary>
        <Column>
          <StyledLine />
          <ThemedText.TextPrimary
            fontSize={isSmallScreen ? 36 : 96}
            fontWeight={800}
            color="primaryBtn_hover"
            // className="active-gradient-text"
            style={{ textShadow: '0px 0px 32px #004360' }}
          >
            <Trans>Airdrop Campaign</Trans>
          </ThemedText.TextPrimary>
          <StyledLine />
        </Column>
      </Column>
      <ThemedText.TextSecondary
        fontSize={14}
        fontWeight={400}
        color="white"
        mt="48px"
        style={{ padding: '0 12px', textAlign: 'center' }}
      >
        <Trans>Enter your invitation code to receive your airdrop</Trans>
      </ThemedText.TextSecondary>

      <Row mt="32px" justify="center" align="center">
        <StyledVerificationInput
          classNames={{
            container: 'container',
            character: 'character',
            characterInactive: 'character--inactive',
            characterSelected: 'character--selected',
          }}
          placeholder=" "
          autoFocus={true}
          value={refCode}
          onChange={handlerChange}
          onComplete={handlerComplete}
        />
      </Row>

      <Row mt={isSmallScreen ? '32px' : '62px'} justify="center" align="center">
        <NextBtn disabled={isDisabled || isPending} onClick={handlerEnter}>
          {isDisabled ? 'Disabled' : isPending ? 'Pending' : 'Next'}
        </NextBtn>
      </Row>
    </CampaignPage>
  )
}
