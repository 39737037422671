import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import Column from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import Tips from 'components/Tips'
import { useAtomValue } from 'jotai/utils'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'

import { usePtsInfoData } from './hooks'
import { teamGoals } from './mis_team'
import { TooltipContent } from './MultiplierTooltip'
import ProgressBar from './ProgressBar'
import { epochAtom } from './Ranking/rankingHooks'

const StyledInfoBox = styled(Box)`
  margin-top: 40px;
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    gap: 24px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const StyledPointBox = styled(Box)`
  flex: 1;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.sectionBorder};
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`

export default function BasicInfo() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)

  // const { userPtsInfo } = useRankingData()
  const epoch = useAtomValue(epochAtom)
  const { roundPtsList } = usePtsInfoData()
  const userPtsInfo = useMemo(() => {
    if (!roundPtsList || epoch < 1) return
    return roundPtsList[epoch - 1]
  }, [epoch, roundPtsList])

  const total = useMemo(() => {
    return BN(userPtsInfo?.pts_trading)
      .plus(userPtsInfo?.pts_lp)
      .plus(userPtsInfo?.pts_luck)
      .plus(userPtsInfo?.pts_base)
  }, [userPtsInfo])

  const progressBarVal = useMemo(() => {
    const val = userPtsInfo?.team_pts / teamGoals[Math.min(Number(userPtsInfo?.team_level) + 1, 5)]?.point
    return val > 1 ? 100 : val * 100
  }, [userPtsInfo?.team_level, userPtsInfo?.team_pts])

  return (
    <StyledInfoBox>
      <Column align="flex-start" gap="8px">
        <Box display="flex" alignItems="center" gap="8px">
          <ThemedText.TextPrimary fontSize={16} fontWeight={800}>
            <Trans>Basic Point</Trans>
          </ThemedText.TextPrimary>
          <Tips tipsStr="Basic Point" />
        </Box>
        <Box display="flex" alignItems="baseline" gap="8px">
          <ThemedText.TextPrimary fontSize={36} fontWeight={700} color="primaryBtn">
            {formatAmount(total, 2, true)}
          </ThemedText.TextPrimary>
          <ThemedText.TextSecondary fontSize={16} fontWeight={600}>
            <Trans>PTS.</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          alignItems="center"
          gap="12px"
          width="100%"
          mt="2px"
        >
          <StyledPointBox>
            <Column gap="4px" className="pointItem">
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>Base</Trans>
              </ThemedText.TextSecondary>
              <Box display="flex" alignItems="center" gap="4px">
                <ThemedText.TextSecondary fontSize={16} fontWeight={700}>
                  {formatAmount(userPtsInfo?.pts_base, 2, true)}
                </ThemedText.TextSecondary>
                <ThemedText.TextSecondary fontSize={12} mb="2px">
                  <Trans>PTS.</Trans>
                </ThemedText.TextSecondary>
              </Box>
            </Column>
          </StyledPointBox>
          <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
            +
          </ThemedText.TextPrimary>
          <StyledPointBox>
            <Column gap="4px" className="pointItem">
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>Trading</Trans>
              </ThemedText.TextSecondary>
              <Box display="flex" alignItems="center" gap="4px">
                <ThemedText.TextSecondary fontSize={16} fontWeight={700}>
                  {formatAmount(userPtsInfo?.pts_trading, 2, true)}
                </ThemedText.TextSecondary>
                <ThemedText.TextSecondary fontSize={12} mb="2px">
                  <Trans>PTS.</Trans>
                </ThemedText.TextSecondary>
              </Box>
            </Column>
          </StyledPointBox>
          <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
            +
          </ThemedText.TextPrimary>
          <StyledPointBox>
            <Column gap="4px" className="pointItem">
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>LP</Trans>
              </ThemedText.TextSecondary>
              <Box display="flex" alignItems="center" gap="4px">
                <ThemedText.TextSecondary fontSize={16} fontWeight={700}>
                  {formatAmount(userPtsInfo?.pts_lp, 2, true)}
                </ThemedText.TextSecondary>
                <ThemedText.TextSecondary fontSize={12} mb="2px">
                  <Trans>PTS.</Trans>
                </ThemedText.TextSecondary>
              </Box>
            </Column>
          </StyledPointBox>
          <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
            +
          </ThemedText.TextPrimary>
          <StyledPointBox>
            <Column gap="4px" className="pointItem">
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>Lucky Draw</Trans>
              </ThemedText.TextSecondary>
              <Box display="flex" alignItems="center" gap="4px">
                <ThemedText.TextSecondary fontSize={16} fontWeight={700}>
                  {formatAmount(userPtsInfo?.pts_luck, 2, true)}
                </ThemedText.TextSecondary>
                <ThemedText.TextSecondary fontSize={12} mb="2px">
                  <Trans>PTS.</Trans>
                </ThemedText.TextSecondary>
              </Box>
            </Column>
          </StyledPointBox>
        </Box>
      </Column>
      <Column align="flex-start" gap="8px">
        <Box display="flex" alignItems="center" gap="8px">
          <ThemedText.TextPrimary fontSize={16} fontWeight={800}>
            <Trans>Multiplier</Trans>
          </ThemedText.TextPrimary>
          <Tips tipsStr={<TooltipContent />} />
        </Box>
        <ThemedText.TextPrimary fontSize={36} fontWeight={700} color="removeBackground">
          {formatAmount(userPtsInfo?.pts_multi, 2, true)}x
        </ThemedText.TextPrimary>
        <Row mt="2px">
          <ProgressBar value={progressBarVal} />
        </Row>
        <RowBetween mt="2px">
          <ThemedText.TextSecondary fontSize={14}>
            <Trans>Team Goals</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextSecondary fontSize={12}>
            {formatAmount(userPtsInfo?.team_pts, 0, true)} /{' '}
            {formatAmount(teamGoals[Math.min(Number(userPtsInfo?.team_level) + 1, 5)]?.point, 0, true)} Total PTS.
          </ThemedText.TextSecondary>
        </RowBetween>
      </Column>
    </StyledInfoBox>
  )
}
