import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as TradeIcon } from 'assets/imgs/airdrop/trade.svg'
import { TradeButton } from 'components/Button'
import Column from 'components/Column'
import { StyledToListIcon } from 'components/Icons/StyledIcon'
import Tips from 'components/Tips'
import { useAtom } from 'jotai'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { formatAmount } from 'utils/formatAmout'

import { usePtsInfoData } from './hooks'
import { epochAtom, useRankingInfo } from './Ranking/rankingHooks'
import RoundEpoch from './Ranking/RoundEpoch'

const StyledToListBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: ${({ theme }) => theme.backgroundInteractive};
  padding: 2px 4px;

  &:hover {
    cursor: pointer;
    background: rgba(0, 178, 255, 0.2);
  }
`

const StyledInfoBox = styled(Box)`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  padding: 28px 0 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export default function TotalInfo() {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()
  const [epoch, setEpoch] = useAtom(epochAtom)
  const { roundList } = useRankingInfo()

  const { totalPts, totalRank, roundPtsList, totalTradingVolume, totalTradingLp } = usePtsInfoData()
  const userRoundPtsInfo = useMemo(() => {
    if (!roundPtsList || epoch < 1) return
    return roundPtsList[epoch - 1]
  }, [epoch, roundPtsList])

  return (
    <StyledInfoBox>
      <Column align="flex-start" gap="4px">
        <Box display="flex" alignItems="center" gap="10px">
          <ThemedText.TextPrimary fontSize={16} fontWeight={800}>
            <Trans>Total</Trans>
          </ThemedText.TextPrimary>
          <Tips tipsStr="Total Points = Basic Points * Multiplier" />
          <RoundEpoch isOpen={isOpen} setIsOpen={setIsOpen} epoch={epoch} setEpoch={setEpoch} roundList={roundList} />
        </Box>
        <Box display="flex" alignItems="baseline" gap="10px">
          <ThemedText.TextPrimary
            fontSize={48}
            fontWeight={700}
            className={`${theme.darkMode ? 'active-gradient-text-dark' : 'active-gradient-text'}`}
          >
            {formatAmount(epoch == 0 ? totalPts : userRoundPtsInfo?.pts_total, 0, true)}
          </ThemedText.TextPrimary>
          <ThemedText.TextSecondary fontSize={16} fontWeight={600}>
            <Trans>PTS.</Trans>
          </ThemedText.TextSecondary>
        </Box>
      </Column>
      <Column align="flex-start" gap="10px">
        <Box display="flex" alignItems="center" gap="4px">
          <ThemedText.TextPrimary fontSize={12} fontWeight={600}>
            <Trans>Rank</Trans>
          </ThemedText.TextPrimary>
          <StyledToListBox
            onClick={() => {
              navigate('/ranking')
            }}
          >
            <ThemedText.TextSecondary fontSize={12} fontWeight={800}>
              <Trans>List</Trans>
            </ThemedText.TextSecondary>
            <StyledToListIcon />
          </StyledToListBox>
        </Box>
        <ThemedText.TextSecondary fontSize={24} fontWeight={600}>
          #{formatAmount(epoch == 0 ? totalRank : userRoundPtsInfo?.rank, 0, true)}
        </ThemedText.TextSecondary>
      </Column>
      <Column align="flex-start" gap="10px">
        <ThemedText.TextPrimary fontSize={12} fontWeight={600}>
          <Trans>Trading Volume</Trans>
        </ThemedText.TextPrimary>
        <Box display="flex" alignItems="center" gap="16px">
          <ThemedText.TextSecondary fontSize={24} fontWeight={600}>
            ${formatAmount(epoch == 0 ? totalTradingVolume : userRoundPtsInfo?.trade_vol, 2, true)}
          </ThemedText.TextSecondary>
          <Box
            onClick={() => {
              navigate('/swap')
            }}
          >
            <TradeButton>
              <Box display="flex" alignItems="center" gap="8px">
                <Trans>Trade</Trans>
                <TradeIcon />
              </Box>
            </TradeButton>
          </Box>
        </Box>
        <ThemedText.TextTertiary fontSize={14} fontWeight={400}>
          ($1,000 = 100 Pts.)
        </ThemedText.TextTertiary>
      </Column>
      <Column align="flex-start" gap="10px">
        <ThemedText.TextPrimary fontSize={12} fontWeight={600}>
          <Trans>LP</Trans>
        </ThemedText.TextPrimary>
        <Box display="flex" alignItems="center" gap="16px">
          <ThemedText.TextSecondary fontSize={24} fontWeight={600}>
            ${formatAmount(epoch == 0 ? totalTradingLp : userRoundPtsInfo?.lp_usd, 2, true)}
          </ThemedText.TextSecondary>
          <Box
            onClick={() => {
              navigate('/pools')
            }}
          >
            <TradeButton>
              <Box display="flex" alignItems="center" gap="8px">
                <Trans>Pools</Trans>
                <TradeIcon />
              </Box>
            </TradeButton>
          </Box>
        </Box>
        <ThemedText.TextTertiary fontSize={14} fontWeight={400}>
          ($1,000 = 100 Pts. per day)
        </ThemedText.TextTertiary>
      </Column>
    </StyledInfoBox>
  )
}
