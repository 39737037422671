import axios from 'axios'
import { useActiveChainId } from 'connection/useActiveChainId'
import { AIRDROP_API } from 'constants/misc'
import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'

export const roundPtsListAtom = atom<any>([])
export const totalGoldAtom = atom<any>(0)
export const totalPointAtom = atom<any>(0)
export const totalPtsAtom = atom<any>(0)
export const totalRankAtom = atom<any>(0)
export const totalTradingVolumeAtom = atom<any>(0)
export const totalTradingLpAtom = atom<any>(0)
export const luckDrawAtom = atom<any>(0)
export const inviteCodeAtom = atom<any>(0)

export function usePtsInfoData() {
  const { account } = useActiveChainId()
  const publicAddress = account?.toLowerCase()

  const [roundPtsList, setRoundPtsList] = useAtom(roundPtsListAtom)
  const [totalGold, setTotalGold] = useAtom(totalGoldAtom)
  const [totalPoint, setTotalPoint] = useAtom(totalPointAtom)
  const [totalPts, setTotalPts] = useAtom(totalPtsAtom)
  const [totalRank, setTotalRank] = useAtom(totalRankAtom)
  const [totalTradingVolume, setTotalTradingVolume] = useAtom(totalTradingVolumeAtom)
  const [totalTradingLp, setTotalTradingLp] = useAtom(totalTradingLpAtom)
  const [luckDraw, setLuckDraw] = useAtom(luckDrawAtom)
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)

  useEffect(() => {
    if (!publicAddress) return
    let times: any

    function fechPtsInfo() {
      axios
        .get(`${AIRDROP_API}/rankinfo/${publicAddress}`)
        .then((res) => {
          // console.log('res rankinfo', res.data)
          setRoundPtsList(res.data.round_info)
          setTotalPts(res.data.all_round_total_point)
          setTotalRank(res.data.all_round_total_rank)
          setLuckDraw(res.data.luck_draw)
          setInviteCode(res.data.invatition_code)
          setTotalGold(res.data.total_blast_gold)
          setTotalPoint(res.data.total_blast_point)
          setTotalTradingLp(res.data.lp_usd)
          setTotalTradingVolume(res.data.trading_volume)
        })
        .catch((error) => {
          if (error.response.data.error == 'User not found') {
            clearInterval(times)
          }
          console.error('rankinfo error', error)
        })
    }

    fechPtsInfo()

    times = setInterval(fechPtsInfo, 10000)

    return () => clearInterval(times)
  }, [
    publicAddress,
    setInviteCode,
    setLuckDraw,
    setRoundPtsList,
    setTotalGold,
    setTotalPoint,
    setTotalPts,
    setTotalRank,
    setTotalTradingLp,
    setTotalTradingVolume,
  ])

  return {
    roundPtsList,
    totalPts,
    totalRank,
    totalGold,
    totalPoint,
    totalTradingVolume,
    totalTradingLp,
    luckDraw,
    inviteCode,
  }
}
